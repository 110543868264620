
const _labels: any = {
    fr: {
       welcome: "Bienvenue",
       form_info: "Merci de télécharger les documents nécessaires à votre mobilité.",
       form_explain: "Si vous n’avez pas votre carte d’identité, scannez uniquement votre permis et remplissez vos données dans le formulaire de la page suivante.",
       helpers: "Documents anglais et français seulement",
       id_card: "Pièce d’identité",
       driving_licence: "Permis de conduire",
       dealership: "Concession",
       select_your_dealership: "Séléctionnez votre concession de rattachement",
       image_info: "Assurez-vous que votre document est de bonne qualité, correctement cadré et pris sur un fond uni.",
       front: "Recto",
       back: "Verso",
       submit: "Soumettre",
       send: "Envoyer",
       footer: "En cas de problème, merci de contacter le support",
       no_document: "Mes documents ne sont ni français ni anglais",
       text_form: "Veuillez vérifier les informations qui ont été remplies automatiquement, puis cliquez sur le bouton “Soumettre” une fois que toutes données auront été correctement remplies.",
       required: "Champs obligatoires",

        title_identity: "Identité",
        surname: "Prénom",
        name: "Nom",
        born_date: "Date de naissance",
        sex: "Civilité",
        address: "Adresse",
        zip_code: "Code postal",
        city: "Ville",
        country: "Pays",
        country_code: "Indicatif",
        place_of_birth: "Lieu de naissance",
        nationality: "Nationalité",
        id_card_number: "Numéro de la CNI",
        delivered_by: "Delivré par",
        delivered_date: "Date de deliverance de la CNI",
        expiration_date: "Date d'expiration de la CNI",
        phone_number: "Numéro de téléphone",

        licence_delivered_by: "Délivré par",
        licence_delivered_date: "Date de deliverance",
        licence_expiration_date: "Date d'expiration",
        licence_number: "Numéro de permis",
        licence_type: "Type de permis",
        b_licence: "Permis B",

        yes: "Oui",
        no: "Non",

        success_title: "Vos documents ont bien été transmis à JLR Mobility.",
        success_care: "Attention",
        success_message: "Nous mettons tout en oeuvre pour assurer votre mobilité.",
        success_message_warning: "Ce pré-enregistrement ne remplace pas les documents originaux que vous devez apporter lors de votre visite en concession. ",

        back_home: "Retour à l'accueil",
        alert_info: "L'opération d'extraction des données nécessite un certain temps. Vous serez redirigé vers le formulaire de saisie si ce délai est trop long",
        unauthenticated: "Le système n'est pas parvenu à vous identifier correctement. Veuillez compléter le formulaire manuellement.",
        error_driving_licence: "Pour mener à bien l’opération, merci de bien vouloir joindre votre permis de conduire.",
    },

    en: {
        welcome: "Welcome",
        form_info: "Please download the documents necessary for your mobility.",
        form_explain: "If you do not have your identity card, just scan your licence and fill in your details in the form on the next page.",
        helpers: "French and British documents only",
        id_card: " Proof of Identity",
        driving_licence: "Driving Licence",
        dealership: "Dealership",
        select_your_dealership: "Select your dealership",
        image_info: "Make sure your document is of good quality, correctly framed and shot against a plain background.",
        front: "Front",
        back: "Back",
        submit: "Submit",
        send: "Send",
        footer: "In case of any issue, please contact support",
        no_document: "My documents are neither French nor English",
        text_form: "Please check the information that has been filled in automatically, then click on the “Submit“ button once all the data has been filled in correctly.",
        required: "Mandatory fields",

        title_identity: "Identity",
        surname: "Surname",
        name: "Name",
        born_date: "Date of birth",
        sex: "Sex",
        address: "Address",
        zip_code: "Postcode",
        city: "City",
        country: "Country",
        country_code: "Country code",
        place_of_birth: "Place of birth",
        nationality: "Nationality",
        id_card_number: "ID card number",
        delivered_by: "Delivered by",
        delivered_date: "Delivery date",
        expiration_date: "Expiration date",
        phone_number: "Phone number",

        licence_delivered_by: "Delivered by",
        licence_delivered_date: "Delivery date",
        licence_expiration_date: "Dxpiration date",
        licence_number: "Licence number",
        licence_type: "Licence type",
        b_licence: "B Licence",
        yes: "Yes",
        no: "No",

        success_title: "Your documents have been sent to JLR Mobility.",
        success_care: "Caution",
        success_message: "We will do everything in our power to ensure your mobility.",
        success_message_warning: "This pre-registration does not replace the original documents that you must bring with you when you visit the dealership.",

        back_home: "Back to home",
        alert_info: "The data extraction operation requires some time. You will be redirected to the entry form if this delay is too long",
        unauthenticated: "The system was unable to identify you correctly. Please complete the form manually.",
        error_driving_licence: "To complete the operation, please attach your driving license.",
    }
}

export default _labels
