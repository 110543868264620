import * as React from "react"
import {Button, Alert, ImagePicker, Loader, ImageInfo, ProgressBar} from "./index"
import {useApp} from "../contexts/appContext"
import {readImagesData} from "../api/index"
import {useState} from "react";



export default function UploadImagesForm({labels, imagesSent}: {labels: any, imagesSent: Function}) {

    const controller = new AbortController()
    const { handleDataCap, stepperToken } = useApp()

    const IDENTITY_CARD_FRONT = 'ic-front'
    const IDENTITY_CARD_BACK = 'ic-back'
    const DRIVING_LICENCE_FRONT = 'dl-front'
    const DRIVING_LICENCE_BACK = 'dl-back'

    // TODO : Générer un token aléatoire et unique pour chaque envoi de fichier

    const [icFront, setICFront] = React.useState<string|undefined>(undefined)
    const [icBack, setICBack] = React.useState<string|undefined>(undefined)
    const [dlFront, setDLFront] = React.useState<string|undefined>(undefined)
    const [dlBack, setDLBack] = React.useState<string|undefined>(undefined)
    const [loading, isLoading] = React.useState<boolean>(false)
    const [errorDrivingLicence, isErrorDrivingLicence] = React.useState<boolean>(false)

    const fileSelected = (target: string, data: string): void => {

      if (target === IDENTITY_CARD_FRONT) {
          setICFront(data)
      }

        if (target === IDENTITY_CARD_BACK) {
            setICBack(data)
        }

        if (target === DRIVING_LICENCE_FRONT) {
            setDLFront(data)
        }

        if (target === DRIVING_LICENCE_BACK) {
            setDLBack(data)
        }
    }

    const handleImages = () => {
        // Seule le permis de conduire est obligatoire
        if (dlFront === undefined || dlBack === undefined) {

            isErrorDrivingLicence(true)

            return false
        }

        isErrorDrivingLicence(false)

        // Construction du formulaire
        const formData = new FormData();

        formData.append('images[]', dlFront);
        formData.append('images[]', dlBack);

        if (icFront) {
            formData.append('images[]', icFront);
        }

        if (icBack) {
            formData.append('images[]', icBack);
        }

        // Envoyer ce token dans la requête de l'utilisateur
        formData.append('stepper_token', stepperToken);

        // Affichage de l'indicateur de chargement
        isLoading(true)

        // Envoie des images au Web Service
        readImagesData(controller, formData).then(response => {

            const {data} = response

            //console.log('readImagesData', response)

            if (data && data?.success) {

                const authenticated = data['Authenticite'] as boolean

                handleDataCap(JSON.stringify(data))

                imagesSent(authenticated)

            }

        }).catch(error => {
            //console.error('readImagesData Error', error.message)
        }).finally(() => isLoading(false))
    }

    React.useEffect(() => {
        return () => controller.abort()
    }, [])

    return <>
        <p>{labels.form_info}</p>

        <p>{labels.form_explain}</p>

        <p><i>{labels.helpers}</i></p>

        <h6>{labels.id_card}</h6>

        <ImageInfo message={labels.image_info} />

        <div className='d-flex justify-content-between'>
            <div className='w-100'>
                <ImagePicker id={IDENTITY_CARD_FRONT} title={labels.front} fileSelected={fileSelected} />
            </div>
            <div className='w-100'>
                <ImagePicker id={IDENTITY_CARD_BACK} title={labels.back} fileSelected={fileSelected} />
            </div>
        </div>
        <br/>
        <br/>

        <h6>{labels.driving_licence}</h6>

        {errorDrivingLicence && (
            <div className='text-danger small'>{labels.error_driving_licence}</div>
        )}

        <ImageInfo message={labels.image_info} />

        <div className='d-flex justify-content-between'>
            <div className='w-100'>
                <ImagePicker id={DRIVING_LICENCE_FRONT} title={labels.front} fileSelected={fileSelected} />
            </div>
            <div className='w-100'>
                <ImagePicker id={DRIVING_LICENCE_BACK} title={labels.back} fileSelected={fileSelected} />
            </div>
        </div>

        <br/>

        <div className='position-relative'>

            <Button title={labels.send} onClick={handleImages} disabled={loading} />

            {loading && <ProgressBar token={stepperToken} />}

            <div className='position-absolute d-none' style={{right: 0, top: 10}}>
                {loading && <Loader />}
            </div>
        </div>

        {/*{loading && <Alert message={labels.alert_info} />}*/}
    </>
}
