import * as React from "react"
import {AppLayout} from "../layouts"
import {useApp} from "../contexts/appContext"
import {Alert, UploadImagesForm, UserInfosForm} from "../components"

export default function ImagesUploaderScreen() {

    const TARGET_FORM = 'form'
    const TARGET_IMAGES = 'images'

    // Par défaut l'interface affiché est celle de téléchargement des documents (CNI et permis) de l'utilisateur
    const [target, setTarget] = React.useState<string>(TARGET_IMAGES)
    // Retourne True si l'utilisateur a pu être identififier à partir des ses documents
    const [identified, isIdentified] = React.useState<boolean>(false)

    const {labels} = useApp()

    const imagesSent = (identified: boolean) => {
        isIdentified(identified)
        setTarget(TARGET_FORM)
    }

    const enableImagesPickupInterface = () => target === TARGET_IMAGES
    const enableHandlerFormInterface = () => target === TARGET_FORM

    const handleUserHaveNoDocumentsToUpload = () => setTarget(TARGET_FORM)

    React.useEffect(() => {
    }, [])

    return (
        <AppLayout>
            <React.Fragment>
                <div className='container pt-5'>
                    <h1>{labels.welcome}</h1>
                    { enableImagesPickupInterface() && <UploadImagesForm labels={labels} imagesSent={imagesSent} /> }
                    { enableHandlerFormInterface() && <UserInfosForm labels={labels} /> }
                    <hr/>
                    <div className='text-white h6'>
                        <button onClick={ handleUserHaveNoDocumentsToUpload } className='bg-transparent border-0 text-white p-0'>
                            <u>{labels.no_document}</u>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        </AppLayout>
    );
}
