import React, {useState, useEffect} from "react"
import styles from './InputText.module.css'
import {Required} from "../index";

type InputTextType = {
    label: string;
    value?: string;
    uid: string;
    required?: boolean;
    items: Array<any>;
    onChanged: (text: string) => void
}

export default function InputRadio({label, value, onChanged, uid, items = [], required = true}: InputTextType) {

    const [text, setValue] = useState<string | undefined>(value)

    const handleChanged = (event: any) => {
        const inputText = event.target.value
        setValue(inputText)
        onChanged(inputText)
    }

    return (
        <div className='form-group my-2'>

            <div className={styles.label}>
                {label} {required && <Required />}
            </div>

            <div className='d-flex'>
                {items.map((item: any) => (
                    <div key={item.value}>
                        <div className='form-check'>
                            <input
                                type='radio'
                                id={`radio-${item.value}`}
                                name={uid}
                                className={`form-check-input ${ highlight(required as boolean, text as string) }`}
                                onChange={handleChanged}
                                value={item.value}
                                required={required}
                                checked={text == item.value}
                            />
                            <label className="form-check-label" htmlFor={`radio-${item.value}`}>
                                {item.label}
                            </label>
                            <span className='px-2'></span>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

const highlight = (required: boolean, value: string) => {

    if (required && value?.length === 0) {
        return 'border-danger'
    }

    return 'bordered-input'
}
