import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faInfo } from '@fortawesome/free-solid-svg-icons'

export default function ImageInfo({message}: {message: string}) {
    return (
        <div className='d-flex align-items-center small my-3' style={{fontSize: 13}}>
            <FontAwesomeIcon icon={faInfoCircle} />
            <div className='px-2'>{message}</div>
        </div>
    )
}



