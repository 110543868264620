// src/App.tsx
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AppProvider from './contexts/appContext';
import { ImagesUploaderScreen, LandingScreen, ImageSentScreen } from './screens';
import Maintenance from './components/Maintenance';
import {AppLayout} from "./layouts"

const App: React.FC = () => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean>(false);

  useEffect(() => {
    fetch('/maintenance.json')
      .then(response => response.json())
      .then(data => setIsUnderMaintenance(data.isUnderMaintenance));
  }, []);

  if (isUnderMaintenance) {
    return (
      <AppProvider>
        <AppLayout>
          <Maintenance />
        </AppLayout>
      </AppProvider>
    );  }

  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/submit" element={<ImagesUploaderScreen />} />
        <Route path="/success" element={<ImageSentScreen />} />
      </Routes>
    </AppProvider>
  );
}

export default App;
