const concessions = [
    { label: "AIX EN PROVENCE", value: "JLRFR00034" },
    { label: "AJACCIO", value: "JLRFR00583" },
    { label: "AMIENS", value: "JLRFR00159" },
    { label: "ANNECY", value: "JLRFR00486" },
    { label: "ANNEMASSE", value: "JLRFR00485" },
    { label: "AVIGNON", value: "JLRFR00166" },
    { label: "BASTIA", value: "JLRFR00519" },
    { label: "BAYONNE", value: "JLRFR00493" },
    { label: "BESANCON", value: "JLRFR00524" },
    { label: "BEZIERS", value: "JLRFR00352" },
    { label: "BORDEAUX PALAU - Sud", value: "JLRFR00003" },
    { label: "BORDEAUX REAL - Nord", value: "JLRFR00527" },
    { label: "BOULOGNE BILLANCOURT", value: "JLRFR00467" },
    { label: "BOULOGNE SUR MER", value: "JLRFR00244" },
    { label: "BRIVE LA GAILLARDE", value: "JLRFR00340" },
    { label: "CAEN", value: "JLRFR00332" },
    { label: "CANNES", value: "JLRFR00541" },
    { label: "CHANTILLY", value: "JLRFR00579" },
    { label: "CHAMBERY", value: "JLRFR00487" },
    { label: "CHARTRES", value: "JLRFR00589" },
    { label: "CLERMONT FERRAND", value: "JLRFR00323" },
    { label: "DIJON", value: "JLRFR00192" },
    { label: "EPINAL", value: "JLRFR00562" },
    { label: "EVREUX", value: "JLRFR00285" },
    { label: "FREJUS", value: "JLRFR00167" },
    { label: "GRENOBLE", value: "JLRFR00580" },
    { label: "LA ROCHE SUR YON", value: "JLRFR00591" },
    { label: "LA ROCHELLE", value: "JLRFR00295" },
    { label: "LES ANDELYS", value: "JLRFR00570" },
    { label: "LES ULIS", value: "JLRFR00536" },
    { label: "LIEVIN", value: "JLRFR00584" },
    { label: "LILLE", value: "JLRFR00283" },
    { label: "LIMOGES", value: "JLRFR00216" },
    { label: "LISIEUX", value: "JLRFR00571" },
    { label: "LORIENT", value: "JLRFR00115" },
    { label: "MACON", value: "JLRFR00329" },
    { label: "MANOSQUE", value: "JLRFR00559" },
    { label: "MARSEILLE", value: "JLRFR00452" },
    { label: "METZ", value: "JLRFR00553" },
    { label: "MONACO", value: "JLRFR00555" },
    { label: "MONTPELLIER", value: "JLRFR00300" },
    { label: "MULHOUSE", value: "JLRFR00246" },
    { label: "NANCY", value: "JLRFR00374" },
    { label: "NANTES", value: "JLRFR00287" },
    { label: "NICE", value: "JLRFR00134" },
    { label: "NIMES", value: "JLRFR00549" },
    { label: "ORLEANS", value: "JLRFR00309" },
    { label: "PARIS 15", value: "JLRFR00148" },
    { label: "PARIS 17", value: "JLRFR00588" },
    { label: "PARIS 19", value: "JLRFR00390" },
    { label: "PERIGUEUX", value: "JLRFR00448" },
    { label: "PERPIGNAN", value: "JLRFR00321" },
    { label: "PORT MARLY", value: "JLRFR00450" },
    { label: "REIMS", value: "JLRFR00109" },
    { label: "RENNES", value: "JLRFR00592" },
    { label: "ROISSY", value: "JLRFR66496" },
    { label: "ROUEN", value: "JLRFR00482" },
    { label: "SAINT MAUR", value: "JLRFR00546" },
    { label: "SOA", value: "JLRFR00395" },
    { label: "SAINT ETIENNE", value: "JLRFR00346" },
    { label: "STRASBOURG", value: "JLRFR00197" },
    { label: "TARBES", value: "JLRFR00132" },
    { label: "TOULON", value: "JLRFR00425" },
    { label: "TOULOUSE Nord", value: "JLRFR00080" },
    { label: "TOULOUSE Sud", value: "JLRFR00417" },
    { label: "TOURS", value: "JLRFR00505" },
    { label: "TROYES", value: "JLRFR00426" },
    { label: "VALENCE", value: "JLRFR00065" },
    { label: "VALENCIENNES", value: "JLRFR71120" },
    { label: "VANNES", value: "JLRFR00593" },
    { label: "LYON NORD", value: "JLRFR00449" },
    { label: "LYON SUD", value: "JLRFR00500" },
    { label: "ASSISTANCE", value: "JLRAssistance" }
];
export default concessions;