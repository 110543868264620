import React, {useState, useEffect} from "react"
import styles from './InputText.module.css'
import {Required} from "../index";

type InputTextType = {
    label: string;
    value?: string;
    uid: string;
    type?: string;
    required?: boolean;
    readonly ?: boolean;
    placeholder?: string;
    onChanged: (text: string) => void
}

export default function Input({label, value, onChanged, uid, placeholder, type = 'text', required = true, readonly = false}: InputTextType) {

    const [text, setValue] = useState<string | undefined>(value)
    const [loading, isLoading] = useState<boolean>(false)

    const handleChanged = (event: any) => {
        const inputText = event.target.value
        setValue(inputText)
        onChanged(inputText)
    }


    return (
        <div className='form-group my-2'>

            <label className={styles.label} htmlFor={uid}>
                {label} {required && <Required />}
            </label>

            <input
                type={type}
                id={uid}
                className={`form-control py-2 bg-dark text-white ${ highlight(required as boolean, text as string) }`}
                placeholder={placeholder}
                onChange={handleChanged}
                value={text}
                required={required}
                readOnly={readonly}
            />
        </div>
    )
}

const highlight = (required: boolean, value: string) => {

    if (required && value?.length === 0) {
        return 'border-danger'
    }

    return 'bordered-input'
}
