import {useApp} from "../contexts/appContext"
import * as React from "react"

export default function Language () {

    const { handleLocale, locale} = useApp()

    const _handle = (value: string): void => {
        handleLocale(value)
    }

    return (
        <div className='d-flex'>

            <button onClick={ () => _handle('fr') } className='bg-transparent border-0'>
                <img className={`rounded ${locale !== 'fr' ? 'grayscale' : ''}`} style={{width: 40, height: 20}} src='/flags/fr.png' alt='En français' />
            </button>

            <button onClick={ () => _handle('en') } className='bg-transparent border-0'>
                <img className={`rounded ${locale !== 'en' ? 'grayscale' : ''}`} style={{width: 40}} src='/flags/en.png' alt='In english' />
            </button>
        </div>
    );
}
