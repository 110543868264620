import * as React from "react"
import {GuestLayout} from "../layouts"
import {useNavigate} from "react-router-dom"

/**
 * Page de lancement de l'application
 * Au bout de 2 seconde l'utilisateur est rediriger vers la page de téléchargement de ses pièces (permis & CNI)
 *
 * @constructor
 */
export default function LandingScreen() {

    const navigate = useNavigate()
    const timeout = 2000

    React.useEffect(() => {
        setTimeout(() => {
            navigate('/submit', { replace: true })
        }, timeout)

    }, [])

    return (
        <GuestLayout>
            <div className='d-flex flex-column w-100 align-items-center h-100 base-landing'>
                <div className='h-100 d-flex align-items-center'>
                    <img className="ml-4" style={{width: 150}} src='/jlr-image.png' alt='Logo Coorporate' />
                </div>
                <div className='h-100 landing'></div>
            </div>
        </GuestLayout>
    );
}
