import * as React from "react";
import {useEffect, useState} from "react";
import {getStepper} from "../api";

type ProgressType = {
    token: string,
}
export default function ProgressBar({token}: ProgressType) {

    const TIMER_INTERVAL = 3000
    const INCREMENT = 0
    const controller = new AbortController()

    const [percentage, setPercentage] = useState<number>(INCREMENT)
    const [progressThem, setProgressTheme] = useState<string>('dark')

    function _handleStepper() {

        getStepper(token, controller).then(result => {
            const {data} = result

            if (data && data.percentage) {
                setPercentage(data.percentage)
            } else {
                setPercentage(0)
            }

            //console.log('_result', data)
        })
    }

    useEffect(() => {

        let stepper = setInterval(_handleStepper, TIMER_INTERVAL)

        return () => {
            clearInterval(stepper)
            return controller.abort()
        }

    }, [])

    if (percentage === undefined || percentage === 0) {
        return null;
    }

    return (
        <div className="progress mt-2" style={{height: 10}}>
            <div
                style={{width: `${percentage}%`}}
                className={`progress-bar progress-bar-striped progress-bar-animated bg-${progressThem}`}
                role="progressbar"
            >{percentage}%</div>
        </div>
    )
}
