import * as React from "react";
import {GuestLayout} from "../layouts"
import {Link,} from "react-router-dom";
import {useApp} from "../contexts/appContext";
export default function ImageSentScreen() {

    const {labels} = useApp()

    React.useEffect(() => {

    }, [])

    return (
        <GuestLayout>
            <div className='px-4 text-center d-flex flex-column w-100 align-items-center justify-content-center h-100 base-app'>
                <img src='/sent.png' width={100} />
                <br/>
                <h3>{labels.success_title}</h3>
                <br/>
                <h3>{labels.success_message}</h3>
                <br/>

                <button className='btn btn-info btn-lg'>
                    <Link to={'/submit'}>
                        <strong className='small text-uppercase'>
                            {labels.back_home}
                        </strong>
                    </Link>
                </button>

                <br/>
                <div>
                    {labels.success_care}
                    <p>{labels.success_message_warning}</p>
                </div>
            </div>
        </GuestLayout>
    );
}
