import * as React from "react"
import {Language} from "../components"
import {Link} from "react-router-dom";
export default function Header() {

    return (
        <header className='mb-2'>
            <nav className="navbar bg-light navbar-expand-lg fixed-top mask-custom shadow-0 border-bottom">
                <div className='d-flex justify-content-between align-items-center w-100 px-4'>
                    <div className='w-100'>
                        <Link to='/'>
                            <img style={{width: 50}} src='/logo.png' alt='Logo Coorporate' />
                        </Link>
                    </div>

                    <div className='w-100 d-flex justify-content-end'>
                        <Language />
                    </div>
                </div>
            </nav>
        </header>
    )
}
