import * as React from 'react';

const Maintenance: React.FC = () => {
  return (
    <div className='container p-5'  style={{ height: '88.5vh' }}>

      <h1 className='py-5'>Site en maintenance</h1>
      <p>Votre site jlrmobility.com est actuellement en cours de maintenance. 
        Il sera de retour dans les meilleurs délais. Merci de votre patience et de votre compréhension.</p>
    </div>
  );
}

export default Maintenance;
