import React, {useEffect, useState} from "react"
import styles from './InputText.module.css'
import Select from 'react-select'
import {Required} from "../index";


type InputTextType = {
    label: string | undefined;
    selected: any;
    uid: string | undefined;
    onChanged: Function
    options: any,
    required?: boolean
}


export default function SelectCustom({label, selected, onChanged, uid, options = [], required = true}: InputTextType) {

    const [selectedOption, setSelectedOption] = useState<any>(selected);
    const handleChange  = (selected: any) => {

        setSelectedOption(selected)

        onChanged(selected)
    }

    const customStyles = {
        control: (base: any) => ({
            ...base,
            backgroundColor: 'rgb(23 23 23)',
            borderColor: highlight(required as boolean, selected),
            borderWidth: 1,
            color: '#fff',
            height: 42,
        }),
        option: (provided: any) => ({
            ...provided,
            backgroundColor: 'rgb(23 23 23)',
            color: '#fff'
        }),
    };


    return (
        <div className='form-group my-2'>
            <label className={styles.label} htmlFor={uid}>
                {label} {required && <Required />}
            </label>
            <Select
                styles={customStyles}
                value={selectedOption}
                onChange={handleChange}
                options={options}
                placeholder={label}
                required={required}
            />
        </div>
    )
}




const highlight = (required: boolean, value: any) => {

    if (required && value === undefined) {
        return '#dc3545'
    }

    return '#044317'
}
