import * as React from "react"
import {Button, Input, Loader, Required} from "./index"
import {useApp} from "../contexts/appContext"
import {InputRadio, Select, SelectCustom} from "./Forms/index.tsx"
import {sendForm} from "../api/index";
import {useNavigate} from "react-router-dom"
import countries from "../data/countries"
import styles from './Forms/InputText.module.css'
import concessions from '../data/concessions';



const _countries = [
    {id: 'FRANCE', title: 'FRANCE'},
    {id: 'ANGLETERRE', title: 'ANGLETERRE'},
]

const _countriesCode = [
    {id: '+33', title: '+33'},
    {id: '+44', title: '+44'},
]

const countriesOptions = countries.map(((item: any) => {
    return {value: item.name, label: item.name?.toUpperCase()}
}));

const countryCodesOptions = countries.map(((item: any) => {
    return {value: item.dial_code, label: item.dial_code}
}));


const selectedCountry = (value: string|undefined) => {
  return countriesOptions.filter((item: any) => item.label === value)[0]
}

const selectedConcession = (value: string|undefined) => {
    return concessions.filter((item: any) => item.value === value)[0]
}


const selectedCountryCode = (value: string|undefined) => {
    return countryCodesOptions.filter((item: any) => item.value === value)[0]
}

const emptyStringIfNull = (value: string|undefined) => {
    return value ? value : ""
}

export default function UserInfosForm({labels}: {labels: any}) {

    const _licenceTypes = [
        {label: labels.yes, value: 1},
        {label: labels.no, value: 0},
    ]

    const [loading, isLoading] = React.useState<boolean>(false)
    const [loadingDate, isLoadingData] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>({})

    const [codeConcessionSelectionnee, setCodeConcessionSelectionnee] = React.useState<string|undefined>('');
    const [surname, setSurname] = React.useState<string|undefined>('')
    const [name, setName] = React.useState<string|undefined>('')
    const [city, setCity] = React.useState<string|undefined>('')
    const [phone, setPhone] = React.useState<string|undefined>('')
    const [countryCode, setCountryCode] = React.useState<string|undefined>('')
    const [country, setCountry] = React.useState<string|undefined>('')
    const [address, setAddress] = React.useState<string|undefined>('')
    const [zipCode, setZipCode] = React.useState<string|undefined>('')


    const [nationality, setNationality] = React.useState<string|undefined>('')
    const [idCardNumber, setIdCardNumber] = React.useState<string|undefined>('')
    const [idCardDeliveryBy, setIdCardDeliveryBy] = React.useState<string|undefined>('')
    const [idCardDeliveryDate, setIdCardDeliveryDate] = React.useState<string|undefined>('')
    const [idCardExpiredDate, setIdCardExpiredDate] = React.useState<string|undefined>('')

    const [bornDate, setBornDate] = React.useState<string|undefined>('')
    const [bornPlace, setBornPlace] = React.useState<string|undefined>('')

    const [licenceDeliveryBy, setLicenceDeliveryBy] = React.useState<string|undefined>('')
    const [licenceDeliveryAt, setLicenceDeliveryAt] = React.useState<string|undefined>('')
    const [licenceExpiredAt, setLicenceExpiredAt] = React.useState<string|undefined>('')
    const [licenceType, setLicenceType] = React.useState<string|undefined>('')
    const [licenceNumber, setLicenceNumber] = React.useState<string|undefined>('')

    const [sex, setSex] = React.useState<string|undefined>('')

    const {getDataCap, handleDataCap} = useApp()
    const navigate = useNavigate();

    const controller = new AbortController()
    const dataCap = getDataCap()


    React.useEffect(() => {
        // Présélectionner la concession "Assistance"
        if (concessions && concessions.length > 0) {
            const concessionAssistance = concessions[concessions.length - 1];            
            setCodeConcessionSelectionnee(concessionAssistance.value);
        }
        if (dataCap !== undefined) {

            //const _data = JSON.parse(dataCap)
            isLoadingData(true)

            setData(dataCap)

            _hydrate(dataCap)

            setTimeout(() => isLoadingData(false), 1000)
        }

        return () => controller.abort()

    }, [])

    const _hydrate = (dataCap: any) => {

        if (dataCap == null) {
            console.log('dataCapnull')
            return null
        }

        const nationality = dataCap['NationaliteCNI']?.toLowerCase();

        setSurname(dataCap['PrenomCNI'])
        setSex(dataCap['GenreCNI'])
        setName(dataCap['NomCNI'])
        setCity(dataCap['Ville'])
        setAddress(dataCap['AdresseCNI'])
        setZipCode(dataCap['CodePostal'])

        setLicenceType(BLicence(dataCap['TypePermis']))
        setLicenceNumber(dataCap['NumeroPermis'])
        setLicenceDeliveryBy(dataCap['DelivreParPermis'])
        setLicenceExpiredAt(dataCap['DateExpireLePermis'])

        setNationality(nationality?.toUpperCase())

        setIdCardNumber(dataCap['NumerodeCNI'])
        setIdCardDeliveryBy(dataCap['PrefectureCNI'])
        setIdCardDeliveryDate(dataCap['DatededelivranceCNI'])
        setIdCardExpiredDate(dataCap['DatedeExpirationCNI'])

        setBornDate(dataCap['DatedenaissanceCNI'])
        setBornPlace(dataCap['LieudenaissanceCNI'])
        setLicenceDeliveryAt(dataCap['DateDelivreLePermis'])

        setCountryCode(_isFrance(nationality) ? '+33' : '+44')
        setCountry(_isFrance(nationality) ? 'FRANCE' : 'ANGLETERRE')
        console.log('dataCap', dataCap);

    }

    const _isFrance = (nationality: string) => {
        return nationality === 'française'
    }

    const BLicence = (type: string) => {
        return type.indexOf('B') >= 0 ? '1' : '0'
    }

    const onChangeConcession = (concession: any) => {
        setCodeConcessionSelectionnee(concession?.value);
    }

    const _submit = (e: any) => {

        if (e) {
            e.preventDefault();
        }        

      isLoading(true)

      //Requête API
      sendForm(controller.signal,{
          'NumerodeCNI': emptyStringIfNull(idCardNumber),
          'NomCNI': name,
          'PrenomCNI': surname,
          'DatedenaissanceCNI': emptyStringIfNull(bornDate),
          'GenreCNI': emptyStringIfNull(sex),
          'AdresseCNI': emptyStringIfNull(address),
          'Ville': emptyStringIfNull(city),
          'Codepostal': emptyStringIfNull(zipCode),
          'Pays': 'FRANCE',
          'NumeroTelephone': countryCode+ ' '+phone,
          'PrefectureCNI': emptyStringIfNull(idCardDeliveryBy),
          'NationaliteCNI': emptyStringIfNull(nationality),
          'LieudenaissanceCNI': emptyStringIfNull(bornPlace),
          'DatededelivranceCNI': emptyStringIfNull(idCardDeliveryDate),
          'DatedeExpirationCNI': emptyStringIfNull(idCardExpiredDate),
          'TypesPermis': licenceType,
          'NumeroPermis': licenceNumber,
          'DelivreParPermis': licenceDeliveryBy,
          'DateDelivreLePermis': licenceDeliveryAt,
          'DateExpireLePermis': licenceExpiredAt,
          'CodeConcessionnaire': codeConcessionSelectionnee,
      }).then(response => {

          const {data} = response
          console.log("response", response);
          console.log("data", data);
              
          handleDataCap(null)
          //navigate('/success', { replace: true })

      }).catch(error => {
          console.log('_submit error', error.message)
      }).finally(() => isLoading(false))

        setTimeout(() => {
            handleDataCap(null)
            navigate('/success', { replace: true })
        }, 2000)
    }

    if (loadingDate) {
        return <Loader />
    }

    return <form onSubmit={_submit}>

        <p>{labels.text_form}</p>
        <p><Required /> {labels.required}</p>

        <h3 className={styles.formTitle}>{labels.title_identity}</h3>

        <Input
            label={labels.name}
            uid='name'
            required={true}
            value={name}
            onChanged={(text: string) => setName(text)}
        />

        <Input
            label={labels.surname}
            uid={'surname'}
            value={surname}
            required={true}
            onChanged={(text: string) => setSurname(text)}
        />

        <Input
            label={labels.born_date}
            type='date'
            uid='born_date'
            required={true}
            onChanged={(text: string) => setBornDate(text)}
            value={bornDate}
        />

        <Input
            label={labels.sex}
            uid='sex'
            required={true}
            onChanged={(text: string) => setSex(text)}
            value={sex}
        />

        <Input
            label={labels.address}
            uid='address'
            required={true}
            onChanged={(text: string) => setAddress(text)}
            value={address}
        />

        <Input
            label={labels.zip_code}
            uid='zip_code'
            required={true}
            onChanged={(text: string) => setZipCode(text)}
            value={zipCode}
        />

        <Input
            label={labels.city}
            uid='city'
            required={true}
            onChanged={(text: string) => setCity(text)}
            value={city}
        />

        <Input
            label={labels.place_of_birth}
            uid={'place_of_birth'}
            required={true}
            value={bornPlace}
            onChanged={(text: string) => setBornPlace(text)}
        />

        <Input
            label={labels.nationality}
            uid='nationality'
            required={true}
            onChanged={(text: string) => setNationality(text)}
            value={nationality}
        />


        <Input
            label={labels.id_card_number}
            uid='id_card_number'
            required={false}
            onChanged={(text: string) => setIdCardNumber(text)}
            value={idCardNumber}
        />

        <Input
            label={labels.delivered_by}
            uid='delivered_by'
            required={false}
            onChanged={(text: string) => setIdCardDeliveryBy(text)}
            value={idCardDeliveryBy}
        />

        <Input
            label={labels.delivered_date}
            type='date'
            uid='delivered_date'
            required={false}
            value={idCardDeliveryDate}
            onChanged={(text: string) => setIdCardDeliveryDate(text)}
        />

        <Input
            label={labels.expiration_date}
            type='date'
            uid={'expiration_date'}
            required={false}
            value={idCardExpiredDate}
            onChanged={(text: string) => setIdCardExpiredDate(text)}
        />

        <div className='d-flex'>
             <div style={{width: 250}}>
                 <SelectCustom
                     selected={selectedCountryCode(countryCode)}
                     label={labels.country_code}
                     uid='Indicatif'
                     required={true}
                     options={countryCodesOptions}
                     onChanged={(countryCode: any) => setCountryCode(countryCode?.label)}
                 />
             </div>

            <p style={{width: 4}}></p>

             <div className='w-100'>
                 <Input
                     label={labels.phone_number}
                     uid='phone_number'
                     required={true}
                     onChanged={(phone: string) => setPhone(phone)}
                     value={phone}
                     type='tel'
                 />
             </div>
        </div>

        {countryCode && countryCode.length > 0 && (
            <div style={{fontSize: 10}} className='text-warning text-uppercase small text-right'>
                {countryCode} {phone && <span>{phone}</span>}
            </div>
        )}

        <br/>

        <h3 className={styles.formTitle}>{labels.driving_licence}</h3>


        <InputRadio
            label={labels.b_licence}
            uid='b_licence'
            items={_licenceTypes}
            onChanged={(text: string) => setLicenceType(text)}
            value={licenceType}
        />

        <Input
            label={labels.licence_number}
            uid='licence_number'
            required={true}
            onChanged={(text: string) => setLicenceNumber(text)}
            value={licenceNumber}
        />

        <Input
            label={labels.licence_delivered_by}
            uid='dl_delivered_by'
            required={true}
            onChanged={(text: string) => setLicenceDeliveryBy(text)}
            value={licenceDeliveryBy}
        />

        <Input
            label={labels.licence_delivered_date}
            type='date'
            uid='dl_delivered_date'
            required={false}
            onChanged={(text: string) => setLicenceDeliveryAt(text)}
            value={licenceDeliveryAt}
        />

        <Input
            label={labels.licence_expiration_date}
            type='date'
            uid={'dl_expiration_date'}
            required={false}
            onChanged={(text: string) => setLicenceExpiredAt(text)}
            value={licenceExpiredAt}
        />

        <br/>

        <h3>{labels.dealership}</h3>
        <div className='d-flex'>
            <div className='w-100'>
            <SelectCustom
                selected={selectedConcession(codeConcessionSelectionnee)}
                label={labels.select_your_dealership}
                uid='concession'
                required={true}
                options={concessions}
                onChanged={(concession: any) => onChangeConcession(concession)}
            />
            </div>
            
        </div>

        <br/>

        <div className='position-relative'>
            <Button title={labels.submit} disabled={loading} onClick={() => {}} />
            <div className='position-absolute' style={{right: 0, top: 10}}>
                {loading && <Loader />}
            </div>
        </div>
    </form>
}
